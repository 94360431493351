@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&display=swap");

.ciao {
  position: absolute;
  top: 24px;
  left: 24px;
  vertical-align: middle;

  .logo {
    height: 50px !important;
    margin: 0 !important;
  }

  span {
    color: #fff;
    margin: 0;
    font-size: 32px;
    vertical-align: middle;
    margin-left: 8px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
  }
}

.p-4-5 {
  padding: 2rem !important;
}

.embed-container {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.splash-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg,#E46665,#E29B65);

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(./Images/bg-md.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.05;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .form {
    width: 480px;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    &.wide {
      width: 880px;
    }
    .midsp {
      margin: 45px 0;
    }
  }

  .logo {
    height: 100px;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .card {
    padding: 2rem;
    border-radius: 1rem;
  }
}

.progress.strength {
  height: 8px;
}

.otp-valid .form-control {
  border-color: #2fb380;
}

.otp-invalid .form-control {
  border-color: #da292e;
}

.otp {
  margin-left: -1%;
  margin-right: -1%;

  .form-control {
    width: 14.6666667%;
    margin-left: 1%;
    margin-right: 1%;
    display: inline-block;
    font-size: 24px;
    text-align: center;
  }
}

.user {
  position: fixed;
  top: 24px;
  right: 24px;
  z-index: 1;

  button {
    background: rgba(0, 0, 0, 0.5);
    padding: 16px 24px;
    color: #fff;
    border: none;
  }

  .dropdown-menu {
    width: 100%;
    margin-top: 8px !important;
    padding: 0 !important;
    border: none;
    overflow: hidden;
  }
}

.wallet-header {
  border-bottom: 1px solid #d6d9dc;
  margin-left: -2rem;
  margin-right: -2rem;
  margin-top: -2rem;
  padding: 1rem;
  position: relative;

  .reload {
    position: absolute;
    color: #000;
    top: 1rem;
    right: 1rem;
    margin: 0;
    padding: 0;
  }

  h4 {
    margin: 0;
    font-size: 1.25rem;
  }

  p {
    margin: 0;
    color: #9b9d9e;
  }
}

.receive {
  .modal-dialog {
    margin-top: 76px;
    position: relative;
    max-width: 400px;
  }

  .icon {
    border: 1px solid #dedede;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0 auto;
    margin-bottom: 1rem;
    transform: translate(-50%, -50%);
    background: #fff;

    img {
      height: 64px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.wallet-body {
  padding: 1rem;
  margin-bottom: -2rem;

  .icon {
    border: 1px solid #dedede;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
    margin-bottom: 1rem;

    img {
      height: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  h1 {
    font-size: 3rem;
    margin: 0;
  }

  h2 {
    font-size: 1.5rem;
    color: #848c95;
    margin-bottom: 2rem;
  }

  .actions {
    margin: 0 auto;
    max-width: 300px;

    .btn-lg {
      border-radius: 50%;
      height: 56px;
      width: 56px;
      position: relative;
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &.fa-rotate-180 {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }

    a,
    .btn-link {
      text-decoration: none;
      padding: 0;
      margin: 0;
    }

    p {
      color: #2fb380;
      margin-top: 4px;
      font-weight: 500;
      margin-bottom: 0;
      font-size: 1rem;
    }
  }
}
